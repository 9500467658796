import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ApiService } from '../../../shared/services/api/api.service';
import { ConsoleService } from '../../../shared/services/console/console.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { CustomerFileService } from '../../../shared/services/model/customerFile/customer-file.service';
import { SettingService } from '../../../shared/services/model/setting/setting.service';

import { CustomerFile } from '../../../shared/interfaces/customer-file';


interface Genre {
  name: string;
  value: string;
}

@Component({
  selector: 'app-form-id',
  templateUrl: './form-id.component.html',
  styleUrls: ['./form-id.component.scss'],
  providers: [MessageService],
})
export class FormIdComponent implements OnInit {

  customer: CustomerFile | undefined;

  genres: Genre[] | undefined;

  languages = [
    { name: '🇫🇷', code: 'fr' },
    { name: '🇬🇧', code: 'en' },
    { name: '🇵🇫', code: 'pf' },
  ];

  token: string = '';
  showConsent: boolean = false;

  otpRequest: boolean = false;
  minDate;
  maxDate;
  countdownTime: number; // 5 minutes in seconds
  countdownDisplay: string = '00:00:00';
  private intervalCountdown: any;

  startQrCodeScanner: boolean = false;


  @Output() updateActiveTabs = new EventEmitter<number>();
  @Output() showNetheosFrame = new EventEmitter<boolean>();

  constructor(
    private _apiService: ApiService,
    private _consoleService: ConsoleService,
    public translateService: TranslateService,
    public customerFileService: CustomerFileService,
    private _settingService: SettingService,
    private activatedRoute: ActivatedRoute,
    private _notificationService: NotificationService
  ) {

    this.activatedRoute.paramMap.subscribe(params => {
      const routeToken = params.get('token');
      this._consoleService.debug('FormIdComponent - URL', 'o', 'ActivatedRoute params:', params);

      this.token = routeToken || localStorage.getItem('token');  // Si le paramètre token n'existe pas, on utilise celui dans localStorage
      this._consoleService.debug('FormIdComponent - token', 'o', 'this.token', this.token);

      if(this.token && this.token.length === 32) {
        localStorage.setItem('token', this.token);
        this.customerFileService.showPhoneField = false;
      } else {
        localStorage.removeItem('token');
        this.customerFileService.showPhoneField = true;
      }
      this.customerFileService.initCustomerFile();
    });

  }

  ngOnInit() {

    this.genres = [
      { name: this.translateService.instant("input_civility_option_1"), value: 'M.' },
      { name: this.translateService.instant("input_civility_option_2"), value: 'Mme' },
    ];

    this.customerFileService.customerFile.language = this.translateService.currentLang;
    const today = new Date();
    this.minDate = new Date( today.getFullYear() - 100, today.getMonth(), today.getDate() ); // 100 years back
    this.maxDate = new Date( today.getFullYear() - 18, today.getMonth(), today.getDate() ); // 18 years back

    // Initialize Reactive Forms
    this.customerFileService.initForms();
  }

  prepareCustomerDataForRequest(): CustomerFile {
    const formValues = this.customerFileService.customerForm.value;

    return {
      customerId: this.customerFileService.customerFile.customerId,
      customerFileId: this.customerFileService.customerFile.customerFileId,
      phone: formValues.phone,
      civility: formValues.civility,
      lastname: formValues.lastname,
      firstname: formValues.firstname,
      birthdate: formValues.birthdate,
      language: this.customerFileService.customerFile.language,
    };
  }

  updateCustomer() {
    // Parcourir tous les champs pour les marquer comme "touché" ou "sale"
    Object.keys(this.customerFileService.customerForm.controls).forEach(cle => {
      const controle = this.customerFileService.customerForm.get(cle);
      controle?.markAsTouched(); // Marque comme touché
      controle?.updateValueAndValidity(); // Met à jour la validité
    });

    if (this.customerFileService.customerForm.invalid) {
      this._consoleService.debug('FormIdComponent - updateCustomer', 'r', 'customerForm', this.customerFileService.customerForm);
      return;
    }

    if (this.customerFileService.showPhoneField) {
      this.showOTPForm();
      return;
    } else {
      this.customerFileService.customerForm.controls['phone'].setValue(this.customerFileService.customerFile.phone);
    }

    const customer = this.prepareCustomerDataForRequest();
    this._consoleService.debug( 'FormIdComponent - updateCustomer', 'b', 'customer', customer );

    this._apiService.post('customers/' + this.customerFileService.customerFile.customerId, customer).subscribe((data) => {
      this._notificationService.showLoading();
      this.processTrustNsign();
    });
  }

  processTrustNsign() {
    const data = {
      customerFileId: this.customerFileService.customerFile.customerFileId,
    };

    this._apiService.post('trust-and-sign-folder', data).subscribe((data) => {
      this._consoleService.debug('FormIdComponent - processTrustNsign', 'o', 'data', data);
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("access_token", data.data.access_token);
      localStorage.setItem("trust_and_sign_client_file_uuid", data.data.trust_and_sign_client_file_uuid);
      // Remplace l'URL sans recharger la page
      window.history.replaceState({}, '', '/identification/' + data.data.token);

      this.customerFileService.customerFile.clientFileUuid = data.data.trust_and_sign_client_file_uuid;

      this.customerFileService.otpForm.reset();
      this.otpRequest = false;
      this.showNetheosFrame.emit(true);
    });
  }

  showOTPForm() {
    this.countdownTime = this._settingService.getByKey("otp_duration");

    const dataToSend = {
      to: this.customerFileService.customerForm.value.phone,
    };

    this._apiService.post('send-otp', dataToSend).subscribe((data) => {
      this._consoleService.debug('FormIdComponent - updateCustomer', 'o', 'data', data);
      this.otpRequest = true;
      this.customerFileService.customerFile.customerFileId = data.data.customer_file_id;
      this.startCountdown();
    });
  }

  validateOtp() {
    // Parcourir tous les champs pour les marquer comme "touché" ou "sale"
    Object.keys(this.customerFileService.otpForm.controls).forEach(cle => {
      const controle = this.customerFileService.otpForm.get(cle);
      controle?.markAsTouched(); // Marque comme touché
      controle?.updateValueAndValidity(); // Met à jour la validité
    });

    if (this.customerFileService.otpForm.invalid) {
      return;
    }

    const customer = this.prepareCustomerDataForRequest();

    customer['otp_code'] = this.customerFileService.otpForm.value.otp_code;
    customer['phone'] = this.customerFileService.customerForm.value.phone;
    customer['customerFileId'] = this.customerFileService.customerFile.customerFileId;
    this._notificationService.showLoading();

    this._apiService.post('verify-otp', customer).subscribe(
      (data) => {
        this._consoleService.debug('FormIdComponent - validateOtp', 'o', 'data', data);
        if (data.status === 'Success') {
          this.customerFileService.customerFile.phone = data.data.phone;
          this.customerFileService.customerFile.customerId = data.data.customer_id;
          this.customerFileService.customerFile.customerFileId = data.data.id;
          this.customerFileService.showPhoneField = false;
          this.customerFileService.otpForm.reset();
          this.otpRequest = false;
          this.updateCustomer();
        } else {
          this._notificationService.showNotification(this.translateService.instant("generic_error_title"), this.translateService.instant("generic_error_description"));
        }
      },
      (error) => {
        this._notificationService.showNotification(this.translateService.instant(error.error.message + "_title"), this.translateService.instant(error.error.message + "_description"));
        this._consoleService.debug('FormIdComponent - validateOtp - error', 'o', 'error', error);
      }
    );
  }

  startCountdown() {
    this.intervalCountdown = setInterval(() => {
      if (this.countdownTime > 0) {
        this.countdownTime--;
        this.updateCountdownDisplay();
      } else {
        clearInterval(this.intervalCountdown); // Stop countdown when time is up
      }
    }, 1000);
  }

  updateCountdownDisplay() {
    const hours = Math.floor(this.countdownTime / 3600);
    const minutes = Math.floor((this.countdownTime % 3600) / 60);
    const seconds = this.countdownTime % 60;

    this.countdownDisplay = this.pad(hours) + ':' + this.pad(minutes) + ':' + this.pad(seconds);
  }

  pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  resendOtp() {
    this.countdownTime = this._settingService.getByKey("otp_duration");
    this.showOTPForm();
  }

  isFieldInvalid(field: string, formGroup: FormGroup = this.customerFileService.customerForm): boolean {
    const control = formGroup.get(field);
    return control && control.invalid && (control.dirty || control.touched);
  }
}
