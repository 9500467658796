import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PrimengModule } from './primeng.module';
import { LanguageComponent } from './components/language/language.component';
import { WelcomeComponent } from '../pages/welcome/welcome.component';
import { LottieComponent } from 'ngx-lottie';

import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';
import { NotificationComponent } from './components/notification/notification.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    WelcomeComponent,
    SidebarComponent,
    LanguageComponent,
    NotificationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengModule,
    LottieComponent,
    NgxScannerQrcodeModule,
    TranslateModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    PrimengModule,
    NgxScannerQrcodeModule,
    WelcomeComponent,
    SidebarComponent,
    LanguageComponent,
    LottieComponent,
    NotificationComponent
  ]
})
export class SharedModule { }
