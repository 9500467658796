<div class="p-4">
  <div>
    <h2 class="text-center" [innerHTML]="'consent_title' | translate"></h2><br/>
    <p [innerHTML]="'consent_description' | translate"></p>
  </div>
  <div class="mb-2 mt-3 d-flex justify-content-start align-items-start position-relative slideInLeftAnimation" [style.animationDelay]="'0.8s'">
    <p-checkbox class="me-2" [(ngModel)]="cgu1" value="1" inputId="cgu1" />
    <label for="cgu1">
      {{ 'consent_input_label_1' | translate }}
    </label>
  </div>
  <div class="mb-2 d-flex justify-content-start align-items-start mb-5 slideInLeftAnimation" [style.animationDelay]="'0.8s'">
    <p-checkbox class="me-2" [(ngModel)]="cgu2" value="1" inputId="cgu2" />
    <label for="cgu2">
      {{ 'consent_input_label_2' | translate }}
    </label>
  </div>
  <button (click)="submitConsent()" class="btn btn-vini-primary btn-lg text-white shadow rounded-pill w-100 mt-3 slideInBottomAnimation" [style.animationDelay]="'1.8s'">
    {{ 'button_validate' | translate }}
  </button>
</div>
