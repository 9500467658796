import { Injectable, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  title: string;
  content: string;
  show: boolean = false;
  showButton: boolean = false;

  constructor(
    public translateService: TranslateService,
  ) { }

  showNotification(title: string, content: string) {
    this.title = title;
    this.content = content;
    this.showButton = true;
    this.show = true;
  }

  setDefaultNotification() {
    this.title = this.translateService.instant("generic_error_title");
    this.content = this.translateService.instant("generic_error_description");
  }

  showDefault() {
    this.setDefaultNotification();
    this.showButton = true;
    this.show = true;
  }

  showLoading() {
    this.title = this.translateService.instant("loading_title");
    this.content = this.translateService.instant("loading_description");
    this.showButton = false;
    this.show = true;
  }

  hideNotification() {
    this.show = false;
  }

  isShow() {
    return this.show;
  }
}
