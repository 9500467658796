import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { ConsoleService } from '../../console/console.service';
import { CustomerFile } from '../../../interfaces/customer-file';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToolsService } from '../../tools/tools.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomerFileService {

  customerFile: CustomerFile;
  customerForm: FormGroup;
  otpForm: FormGroup;
  showPhoneField: boolean = false;

  constructor(
    private _apiService: ApiService,
    private _consoleService: ConsoleService,
    private _toolsService: ToolsService,
    private fb: FormBuilder,
    private _router: Router
  ) { }

  setCustomerFile(customer: CustomerFile) {
    this.customerFile = customer;
    this.initForms();
  }

  initCustomerFile() {
    this.customerFile = {
      customerId:0,
      customerFileId:0,
      phone:"",
      civility:"",
      lastname:"",
      firstname:"",
      birthdate:"",
      otp_code:"",
      language: localStorage.getItem('language') || 'fr',
      esim:false
    };
    const token = localStorage.getItem('token');
    if(token && (token.length == 32)){
      // localStorage.clear();
      localStorage.setItem('token', token);
      this.getCustomerFileByToken(token);
    }
  }

  getCustomerFileByToken(token, updateStepper = true) {
    this._apiService.get('customer-files/token/' + token).subscribe((data: any) =>{
      this._consoleService.debug('CustomerFileService - getCustomerFileByToken', 'o', 'data', data);

      // if(data.data.esim)
      if(updateStepper) this.updateStepper(data, token)

      if(data.data.birthdate){
        const [datePart] = data.data.birthdate.split('T');
        const [year, month, day] = datePart.split('-');

        data.data.birthdate = `${day}/${month}/${year}`;
      }
      this.setCustomerFile(data.data);
    });
  }

  updateStepper(data, token){
    if(data.data.hasOwnProperty('trust_and_sign_client')){
      if(data.data.trust_and_sign_client.state === "SUSPENDED"){
        localStorage.setItem('stepper', '1');
        this._router.navigate(['/identification/' + token]);
      } else if(data.data.trust_and_sign_client.state === "ACCEPTED"){
        localStorage.setItem("idenficationIsDone", "true");
        if(data.data.consent){
          localStorage.setItem("consentIsDone", "true");
          localStorage.setItem('stepper', '3');
          this._router.navigate(['/felicitations/' + token]);
        }else{
          localStorage.setItem('stepper', '2');
          this._router.navigate(['/identification/' + token]);
        }
      }
    } else {
      localStorage.setItem('stepper', '0');
    }
  }

  updateCustomerFileFromTrustNsign(event){
    this._consoleService.debug('CustomerFileService - updateCustomerFileFromTrustNsign', 'b', 'this.customerFile', this.customerFile);
    let notifications = [
      {
        "id": 167842,
        "date": "2018-10-31T08:46:02.956Z",
        "event": event,
        "clientFileUuid": this.customerFile.clientFileUuid,
      }
    ]
    this._apiService.post('netheos/webhook', {notifications: JSON.stringify(notifications)}).subscribe((data) => {
      this._consoleService.debug('CustomerFileService - updateCustomerFileFromTrustNsign', 'o', 'data', data);
    });
  }

  initForms() {
    this.customerForm = this.fb.group({
      phone: [
        this.customerFile.phone,
        [
          Validators.required,
          Validators.pattern(/^(?:\+689|689)?87[0-9]{6}$/), // Adjust pattern as needed
        ],
      ],
      civility: [
        this.customerFile.civility,
        Validators.required,
      ],
      lastname: [
        this.customerFile.lastname,
        Validators.required,
      ],
      firstname: [
        this.customerFile.firstname,
        Validators.required,
      ],
      birthdate: [
        this.customerFile.birthdate,
        [Validators.required, this._toolsService.ageValidator(18)],
      ],
    });

    this.otpForm = this.fb.group({
      otp_code: ['', [Validators.required, Validators.pattern(/^[0-9]{6}$/)]],
    });

    this.initFormWithCustomerFile();
  }

  initFormWithCustomerFile(){
    this._consoleService.debug('FormIdComponent - initFormWithCustomerFile', 'b', 'this.customerFile', this.customerFile);
    this.customerForm.patchValue({
      phone: this.customerFile.phone,
      civility: this.customerFile.civility,
      lastname: this.customerFile.lastname,
      firstname: this.customerFile.firstname,
      birthdate: this.customerFile.birthdate,
    });
  }

  clear(){
    localStorage.removeItem("token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("trust_and_sign_client_file_uuid");
    this.customerFile = null;
    this.showPhoneField = true;
    this.initCustomerFile();
    this.initFormWithCustomerFile();

  }
}
