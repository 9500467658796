import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ConsoleService } from '../console/console.service';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor(
    private _consoleService: ConsoleService
  ) { }

  checkIfMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];

    // Combiner la vérification de la taille de l'écran et du userAgent
    if (/android|iPhone|iPad|iPod/i.test(userAgent) || window.innerWidth <= 768) {
      return true;
    }

    return false;
  }

  ageValidator(minAge: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null; // Required validator will handle empty value
      }
      this._consoleService.debug('ToolsService - ageValidator', 'b', 'value', value);
      const [year, month, day] = value.split('-');
      const birthDate = new Date(+year, +month - 1, +day);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age >= minAge ? null : { ageInvalid: true };
    };
  }

}
