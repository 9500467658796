import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  loginField: string | undefined;
  passwordField: string | undefined;
  email: string = '';
  password: string = '';
  constructor(
    private _route : Router
  ){

  }
  ngOnInit(): void {
  }

  login(){
    if(this.loginField && this.passwordField){
      this._route.navigateByUrl('/')
    }
  }
}
