import { Component, effect, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SidebarService } from '../../services/sidebar/sidebar.service';
import { signal } from '@angular/core';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {

  @Output() showLanguageForm = new EventEmitter<boolean>();

  constructor(public sidebarService: SidebarService) {}

  ngOnInit(): void {

  }

  onSidebarVisibleChange(newValue: boolean) {
    if (!newValue) {
      this.sidebarService.hide();
    }
  }
}
